const BundleBasedDevServer = () => (
	<svg
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 1896 1071"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		width="672"
		height="380">
		<text fill="#FFAA3E" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-size="80" letter-spacing="0em">
			<tspan x="46" y="132.344" fill="#FFAA3E">
				Bundle based dev server
			</tspan>
		</text>
		<rect x="48" y="239" width="1086" height="767" rx="98" stroke="#FFC36B" stroke-width="4px" fill="none"></rect>
		<rect x="108" y="577" width="212" height="83" rx="10" fill="#C3E88C"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="170" y="631.488" fill="#15505C">
				entry
			</tspan>
		</text>
		<rect x="476" y="712" width="212" height="88" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0.33em">
			<tspan x="552.5" y="768.988" fill="#15505C">
				···
			</tspan>
		</text>
		<rect x="476" y="438" width="212" height="88" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="537" y="494.988" fill="#15505C">
				route
			</tspan>
		</text>
		<rect x="473" y="576" width="212" height="88" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="534" y="632.988" fill="#15505C">
				route
			</tspan>
		</text>
		<path
			d="M472.614 481.699L438.815 489.291L462.289 514.766L472.614 481.699ZM324.582 622.18L454.791 502.201L450.726 497.789L320.516 617.768L324.582 622.18Z"
			fill="#E06666"></path>
		<path d="M469 620L439 602.679V637.321L469 620ZM323 623H442V617H323V623Z" fill="#E06666"></path>
		<path
			d="M472.614 756.105L462.032 723.12L438.757 748.777L472.614 756.105ZM320.533 622.196L450.601 740.186L454.632 735.742L324.565 617.752L320.533 622.196Z"
			fill="#E06666"></path>
		<path
			d="M822.052 905.098L815.036 871.175L789.166 894.213L822.052 905.098ZM689.041 760.243L801.856 886.929L806.337 882.939L693.521 756.253L689.041 760.243Z"
			fill="#FFC36B"></path>
		<path
			d="M819.908 756.105L811.894 722.403L786.715 746.195L819.908 756.105ZM689.1 622.034L799.185 738.54L803.546 734.419L693.462 617.914L689.1 622.034Z"
			fill="#FFC36B"></path>
		<path
			d="M817.765 623.19L788.215 605.112L787.334 639.742L817.765 623.19ZM691.205 622.973L790.697 625.502L790.85 619.504L691.357 616.975L691.205 622.973Z"
			fill="#FFC36B"></path>
		<path
			d="M818.837 481.699L789.286 463.622L788.406 498.252L818.837 481.699ZM692.277 481.483L791.769 484.012L791.922 478.014L692.429 475.485L692.277 481.483Z"
			fill="#FFC36B"></path>
		<path
			d="M819.909 340.209L786.924 350.795L812.584 374.067L819.909 340.209ZM696.719 480.499L803.992 362.224L799.547 358.193L692.275 476.468L696.719 480.499Z"
			fill="#FFC36B"></path>
		<path
			d="M817.765 614.614L810.467 580.751L784.789 604.002L817.765 614.614ZM692.273 480.497L797.418 596.614L801.866 592.587L696.721 476.47L692.273 480.497Z"
			fill="#FFC36B"></path>
		<rect x="822" y="288" width="212" height="88" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="864" y="344.988" fill="#15505C">
				module
			</tspan>
		</text>
		<rect x="822" y="435" width="212" height="87" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="864" y="491.488" fill="#15505C">
				module
			</tspan>
		</text>
		<rect x="820" y="571" width="212" height="88" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="862" y="627.988" fill="#15505C">
				module
			</tspan>
		</text>
		<rect x="822" y="718" width="212" height="87" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="864" y="774.488" fill="#15505C">
				module
			</tspan>
		</text>
		<rect x="822" y="864" width="212" height="88" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0.33em">
			<tspan x="898.5" y="920.988" fill="#15505C">
				···
			</tspan>
		</text>
		<path d="M1239 627L1209 609.679V644.321L1239 627ZM1136 630H1212V624H1136V630Z" fill="#FFC36B"></path>
		<path d="M1596 627L1566 609.679V644.321L1596 627ZM1493 630H1569V624H1493V630Z" fill="#FFC36B"></path>
		<rect x="1239" y="545" width="254" height="144" rx="10" fill="#C692EA"></rect>
		<text
			fill="#FFFFFF"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1306.5" y="629.988" fill="#FFFFFF">
				Bundle
			</tspan>
		</text>
		<rect x="1596" y="543" width="254" height="143" rx="10" fill="#009688"></rect>
		<text
			fill="#FFFFFF"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1667.71" y="604.988" fill="#FFFFFF">
				Server
			</tspan>
			<tspan x="1675.76" y="649.988" fill="#FFFFFF">
				ready
			</tspan>
		</text>
	</svg>
);

export default BundleBasedDevServer;
