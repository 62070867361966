const NativeEsmBasedDevServer = () => (
	<svg
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 1896 1071"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		width="672"
		height="380">
		<text fill="#FFAA3E" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-size="80" letter-spacing="0em">
			<tspan x="45" y="129.344" fill="#FFAA3E">
				Native ESM based dev server
			</tspan>
		</text>
		<rect x="632" y="526" width="273" height="106" rx="10" fill="#C3E88C"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="724.5" y="591.988" fill="#15505C">
				entry
			</tspan>
		</text>
		<rect x="1106" y="699" width="274" height="114" rx="10" fill="#666665"></rect>
		<g filter="url(#filter0_d_5_61)" fill="none">
			<text
				fill="#CCCCCB"
				xmlSpace="preserve"
				style={{ whiteSpace: 'pre' }}
				font-size="38"
				font-weight="600"
				letter-spacing="0.33em">
				<tspan x="1213.5" y="768.988" fill="#CCCCCB">
					···
				</tspan>
			</text>
		</g>
		<rect x="1106" y="346" width="274" height="113" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1198" y="415.488" fill="#15505C">
				route
			</tspan>
		</text>
		<rect x="1102" y="524" width="273" height="114" rx="10" fill="#666665"></rect>
		<text
			fill="#CCCCCB"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1193.5" y="593.988" fill="#CCCCCB">
				route
			</tspan>
		</text>
		<path
			d="M1101.79 402.463L1067.99 410.054L1091.46 435.529L1101.79 402.463ZM910.168 583.106L1083.96 422.965L1079.9 418.553L906.102 578.693L910.168 583.106Z"
			fill="#C892E9"></path>
		<path d="M1097 581L1067 563.679V598.321L1097 581ZM908 584H1070V578H908V584Z" fill="#999899"></path>
		<path
			d="M1101.79 756.57L1091.2 723.584L1067.93 749.242L1101.79 756.57ZM906.119 583.121L1079.77 740.651L1083.8 736.207L910.151 578.677L906.119 583.121Z"
			fill="#999899"></path>
		<path
			d="M1552.72 948.839L1545.7 914.916L1519.83 937.953L1552.72 948.839ZM1381.73 761.331L1532.52 930.67L1537 926.68L1386.21 757.341L1381.73 761.331Z"
			fill="#999899"></path>
		<path
			d="M1549.95 756.569L1541.94 722.868L1516.76 746.659L1549.95 756.569ZM1381.79 582.96L1529.23 739.005L1533.59 734.884L1386.15 578.839L1381.79 582.96Z"
			fill="#999899"></path>
		<path
			d="M1547.19 585.049L1517.64 566.972L1516.76 601.602L1547.19 585.049ZM1383.89 583.898L1520.12 587.362L1520.27 581.364L1384.04 577.9L1383.89 583.898Z"
			fill="#999899"></path>
		<path
			d="M1548.57 402.463L1519.02 384.386L1518.14 419.015L1548.57 402.463ZM1385.27 401.312L1521.5 404.776L1521.66 398.778L1385.43 395.314L1385.27 401.312Z"
			fill="#C892E9"></path>
		<path
			d="M631.489 585.049L601.583 567.567L601.396 602.207L631.489 585.049ZM375.576 586.666L604.473 587.903L604.506 581.903L375.608 580.666L375.576 586.666Z"
			fill="#C892E9"></path>
		<path
			d="M1549.95 219.877L1516.97 230.462L1542.63 253.735L1549.95 219.877ZM1390.34 400.329L1534.04 241.892L1529.59 237.861L1385.89 396.298L1390.34 400.329Z"
			fill="#C892E9"></path>
		<path
			d="M1547.19 573.983L1539.89 540.12L1514.21 563.372L1547.19 573.983ZM1385.89 400.327L1526.84 555.983L1531.29 551.956L1390.34 396.3L1385.89 400.327Z"
			fill="#C892E9"></path>
		<rect x="1553" y="152" width="274" height="113" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1626" y="221.488" fill="#15505C">
				module
			</tspan>
		</text>
		<rect x="1553" y="341" width="274" height="114" rx="10" fill="#4FC08D"></rect>
		<text
			fill="#15505C"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1621.5" y="411.818" fill="#15505C">
				module
			</tspan>
		</text>
		<rect x="1550" y="517" width="274" height="114" rx="10" fill="#666665"></rect>
		<text
			fill="#CCCCCB"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1623" y="586.988" fill="#CCCCCB">
				module
			</tspan>
		</text>
		<rect x="1553" y="707" width="274" height="113" rx="10" fill="#666665"></rect>
		<text
			fill="#CCCCCB"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="1626" y="776.488" fill="#CCCCCB">
				module
			</tspan>
		</text>
		<rect x="1553" y="896" width="274" height="113" rx="10" fill="#666665"></rect>
		<text
			fill="#CCCCCB"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0.33em">
			<tspan x="1660.5" y="965.488" fill="#CCCCCB">
				···
			</tspan>
		</text>
		<rect x="45" y="491" width="330" height="179" rx="10" fill="#029788"></rect>
		<text
			fill="#FFFFFF"
			xmlSpace="preserve"
			style={{ whiteSpace: 'pre' }}
			font-size="38"
			font-weight="600"
			letter-spacing="0em">
			<tspan x="154.707" y="570.988" fill="#FFFFFF">
				Server
			</tspan>
			<tspan x="162.76" y="615.988" fill="#FFFFFF">
				ready
			</tspan>
		</text>
		<line
			x1="507.615"
			y1="459.201"
			x2="506.232"
			y2="569.859"
			stroke="#C892E9"
			stroke-width="4px"
			stroke-dasharray="8 8"
			fill="none"></line>
		<line
			x1="1038.78"
			y1="733.073"
			x2="1037.37"
			y2="883.845"
			stroke="#E06666"
			stroke-width="4px"
			stroke-dasharray="8 8"
			fill="none"></line>
		<text fill="#E06666" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-size="38" letter-spacing="0em">
			<tspan x="918" y="938.988" fill="#E06666">
				Dynamic import
			</tspan>
			<tspan x="918" y="983.988" fill="#E06666">
				(code split point)
			</tspan>
		</text>
		<text fill="#C892E9" xmlSpace="preserve" style={{ whiteSpace: 'pre' }} font-size="38" letter-spacing="0em">
			<tspan x="399" y="431.488" fill="#C892E9">
				HTTP request
			</tspan>
		</text>
		<defs>
			<filter
				id="filter0_d_5_61"
				x="1212.15"
				y="752.766"
				width="60.9863"
				height="13.2324"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB">
				<feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"></feColorMatrix>
				<feOffset dy="4"></feOffset>
				<feGaussianBlur stdDeviation="2"></feGaussianBlur>
				<feComposite in2="hardAlpha" operator="out"></feComposite>
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_61"></feBlend>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_61" result="shape"></feBlend>
			</filter>
		</defs>
	</svg>
);

export default NativeEsmBasedDevServer;
