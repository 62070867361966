import lottie from 'lottie-web';
import animationData from './src/components/animations/Loading_animation_02.json';

export const onClientEntry = () => {
	const container = document.getElementById('lottie-container');
	const animation = lottie.loadAnimation({
		container: container,
		renderer: 'svg',
		loop: false,
		autoplay: true,
		animationData: animationData,
	});

	animation.setSpeed(2.2);
	animation.play();

	animation.onComplete = () => {
		container.style.opacity = 0;
		container.style.zIndex = 1;
		setTimeout(() => {
			animation.destroy();
			document.getElementById('lottie-mask').remove();
		}, 150);
	};
};

export const shouldUpdateScroll = () => {
	return [0, 0];
};

export const onPreRouteUpdate = () => {
	document.querySelector('html').removeAttribute('style');
};
